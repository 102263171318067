import axiosInstance from ".";

export async function sendOtp(loginData) {
  try {
    // console.log("sendOtp", loginData);
    const response = await axiosInstance.post(
      "/auth/patient/request",
      loginData
    );
    if (!response) {
      throw new Error("Could not send OTP");
    }
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function verifyOtp(verifyLoginData) {
  try {
    // console.log("verifyOtp", verifyLoginData);
    const response = await axiosInstance.post("/auth/verify", verifyLoginData);
    if (!response) {
      throw new Error("Could not send OTP");
    }
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function patientLogOut() {
  try {
    const response = await axiosInstance.post("/patient/logout",);
    return response;
  } catch (err) {
    console.log(err);
  }
}
